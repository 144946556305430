import React from "react";
import { IUpdateSearchModalProps } from "./UpdateSearchModalProps";
import { StyledContainer, StyledModal} from "./UpdateSearchModalHelpers";
import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UpdateSelectRoomCriteria } from "../NewSelectRoomHelpers";

const UpdateSearchModal = (props: IUpdateSearchModalProps) => {

  return (
    <StyledModal
      {...props}
      show={props.show}
      onHide={props.onHide}
      aria-label="Hotel Search"
      className="modal-fullscreen"
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center my-4 logo">
          {''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledContainer>
          <Row>
            <Col className="justify-content-center">
            <Col className="justify-content-center mt-4 modalBody">
                <UpdateSelectRoomCriteria 
                checkout={props.checkout}
                reservation={props.reservation}
                updateSearch={props.updateSearch}
                isEditReservationModal = {true}
                HandleLrrSelect={props.HandleLrrSelect}
                />
            </Col>
            </Col>
          </Row>
        </StyledContainer>
      </Modal.Body>
    </StyledModal>
  );
};

export default UpdateSearchModal;
