import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { useHotels } from "../../../hooks/useHotels";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { searchSelector } from "../../../redux/slices/Search/search";
import { RoomOccupancyInfo } from "./OccupancyMessageHelpers";
import { IOccupancyMessageProps } from "./OccupancyMessageProps";
import { useSelector } from "react-redux";

export const OccupancyMessage = (
  props: IOccupancyMessageProps
): JSX.Element => {
  const { page, crsCode, className = "", roomIndex = 0 } = props;
  const search = useAppSelector(searchSelector);
  const checkout = useSelector((state: any) => state.editReservationCheckout);
  const hotels = useHotels();
  const [message, setMessage] = useState("");
  const [showOccupancy, setShowOccupancy] = useState(false);
  const [showOccupancyMessage, setShowOccupancyMessage] = useState(false);
  const isMobileOnly = useMobileDetect();

  useEffect(() => {
    const hotel = hotels.filter(
      (h: { crs_code: string }) => h.crs_code == crsCode
    );
    if (hotel && hotel.length && hotel[0]?.field_occupancy_messaging) {
      setShowOccupancy(true);
      setShowOccupancyMessage(true);
    }
  }, [hotels, crsCode]);

  useEffect(() => {
    let _message = "";
    let adults = 0;
    let children = 0;
    if (page == "search" || page == "selectroom") {
      adults = search.rooms[roomIndex].adults;
      children = search.rooms[roomIndex].children;
    } else if (page == "selectroom_edit_reservation") {
      const index = Object.keys(checkout.Rooms)[0];
      const room = checkout.Rooms[index];
      adults = room.adults;
      children = room.children;
    } else {
      search.rooms.forEach((r) => {
        adults += r.adults;
        children += r.children;
      });
    }

    const adultWord = page === "search" ? "adult" : "Adult";
    const childWord = page === "search" ? "child" : "Child";
    const adultsText = `${adults} ${adultWord}${adults > 1 ? "s" : ""}`;
    const childrenText =
      children > 0
        ? `, ${children} ${childWord}${children > 1 ? "ren" : ""}`
        : "";
    _message = `${adultsText}${childrenText} per night`;
    setMessage(_message);
  }, [search, page, roomIndex, checkout]);

  return page == "selectroom" || page == "selectroom_edit_reservation" ? (
    <>
      {showOccupancyMessage ? (
        <RoomOccupancyInfo
          onClose={() => setShowOccupancyMessage(false)}
          message={message}
          className={props.className}
        />
      ) : null}
    </>
  ) : (
    <span
      id="occupancy-info"
      className={className}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
};
export default OccupancyMessage;
