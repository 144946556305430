import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";
import { roomOccupancy, occupancyInfo } from "./OccupancyMessage.module.scss";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const RoomOccupancyInfo = (props: { onClose: (arg0: boolean) => void; message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; className?:any }) => {
    return (
        <Alert
            variant="info"
            id="room-occupancy-info"
            className={`text-start ${props.className? occupancyInfo : roomOccupancy}`}
            onClose={() =>
                props.onClose(false)
            }
            dismissible
        >
            <FontAwesomeIcon icon={faInfoCircle} className="me-2" /> {props.message}
        </Alert>
    );
};