import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IUnlockPriceButtonProps } from "./UnlockPriceButtonProps";
import { StyledButton } from "./UnlockPriceButtonHelpers";

import { useUnlockDiscount } from "../../../hooks/useUnlockDiscount";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  unlockBtnClass,
  unlockSuccessClass,
} from "./UnlockPriceButton.module.scss";

const UnlockPriceButton = ({ unlockClass }: IUnlockPriceButtonProps) => {
  const [
    showUnlockButton,
    discountUnlocked,
    showSpecialPricing,
    setShowSpecialPricing,
  ] = useUnlockDiscount("");

  const handleUnlockPrice = () => {
    if (discountUnlocked) {
      return;
    }
    setShowSpecialPricing(true);
  };
  return (
    <StyledButton
      onClick={handleUnlockPrice}
      unlockClass={`${
        discountUnlocked ? unlockSuccessClass : unlockBtnClass
      } ${unlockClass}`}
    >
      {discountUnlocked ? "Member Rate Unlocked" : "View Member Rate"}
      {discountUnlocked ? (
        <>
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faCheck} color="#349F17" />
        </>
      ) : null}
    </StyledButton>
  );
};
export default UnlockPriceButton;
